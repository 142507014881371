import React, { useState, useEffect } from 'react';
import { FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Label, DropdownToggle, DropdownMenu, Dropdown, DropdownItem } from 'reactstrap';
import { updatePaymentAccountService, GetPartyIdListService } from './../../../services/paymentAgreementService/paymentAgreementService';
import { alphanumericFormat } from './../../../common/constants';

const PaymentAccountPopup = ({ isOpenPaymentsAccountPopup, togglePaymentsAccountModelPopup, fetchClientList, selectedClient, message}) => {
    const [paymentAccount, setPaymentAccount] = useState('');
    const [partyIdList, setPartyIdList] = useState([]);
    const [isPartyIdopen, setIsPartyIdopen] = useState(false);
    const [paymentPartyId, setPaymentPartyId] = useState({});
    useEffect(() => {
        let account = selectedClient && selectedClient !== null && selectedClient.payAcct ? selectedClient.payAcct : '';
        let paymentPartyId = selectedClient && selectedClient !== null && selectedClient.partyId ? selectedClient.partyId    : '';
        setPaymentAccount(account);
        if (account !== '')
            getPartyIdList(account, 'init', paymentPartyId);
    }, [selectedClient]);

    const save = () => {
        let reqJson = { "pkid": selectedClient.pKid, "accountnumber": paymentAccount, "pmtaccounttype": "PMT", "paymentpartyid": paymentPartyId.partyid };
        updatePaymentAccountService(reqJson).then(res => {
            if (res.status === "success") {
                togglePaymentsAccountModelPopup();
                fetchClientList();
            } else {
                togglePaymentsAccountModelPopup();
                message.error("We are unable to update payment account, you can try again.");
            }
        })
            .catch(err => {
                console.error(err);
                message.error("We are unable to update payment account, you can try again.");
                togglePaymentsAccountModelPopup();
            })    
    }
    const handleFormChanges = evt => {
        let value = evt.target.value;
        if (evt.target.name === "paymentAccount" && value.length <= 20 && (value === "" || alphanumericFormat.test(value))) {
            setPaymentAccount(value);
        } 
    }
    const handleOnBlur = (evt) => {
        let accountKey = evt && evt.target ? evt.target.value : evt;
        getPartyIdList(accountKey, 'onBlur');
       
    }

    const getPartyIdList = (accountKey, source, partyId='') => {

        GetPartyIdListService(accountKey).then(res => {
            if (res && res && Array.isArray(res) && res.length > 0) {
                setPartyIdList(res);
                if (source === 'init') {
                    if (res.length === 1) {
                        setPaymentPartyId({ partyid: res[0].partyid, partyname: res[0].partyname });
                    } else {
                        for (const [, list] of res.entries()) {
                            if (list.partyid === partyId) {
                                setPaymentPartyId(
                                     { partyid: partyId, partyname: list.partyname }
                                );
                            }
                        }
                    }
                }
                
            }
            else {
                message.error("Unable to fetch Party Id.");
                console.error("Unable to fetch Party Id.");
            }
        })
            .catch(err => {
                message.error("Unable to fetch Party Id.");
                console.error("Unable to fetch Party Id: " + err);
            })
    }
    return (
        <>
            <Modal isOpen={isOpenPaymentsAccountPopup} toggle={togglePaymentsAccountModelPopup}
                className="RCM_CM_modal"
                wrapClassName="RCM_CM_modal_wrapper"
                modalClassName="RCM_CM_modal_class"
                contentClassName="RCM_CM_modal_content"
                zIndex='9999'
                centered
                key='PAMP01'>
                <ModalHeader toggle={togglePaymentsAccountModelPopup} className="RCM_externalAssetModel_header RCM_manage_group_header" key="PAMP02">

                    <label className="RCM_tile_heading" key="PAMP03">EDIT PAYMENT ACCOUNT</label>
                </ModalHeader>
                <ModalBody key="PAMP04">

                    <div key="PAMP05">
                        
                        <FormGroup key="PAMP06">
                            <div className="RCM_label_wrapper" key="PAMP07">
                                <Label for="type" key="PAMP08">Please enter payment account for: {selectedClient && selectedClient !== null && selectedClient.name}</Label>
                            </div>
                            
                            <input
                                type="text"
                                autoComplete="off"
                                placeholder=""
                                name="paymentAccount"
                                className="RCM_form_control RCM_Rules_control"
                                value={paymentAccount}
                                onChange={evt => handleFormChanges(evt)}
                                onBlur={evt => handleOnBlur(evt)}
                                key="PAMP08"
                            />
                            
                        </FormGroup>
                        {partyIdList.length > 0
                            ? <div className='RCM_dropdown_partyID' key="PAMP001">
                                <FormGroup key="PAMP002">
                                    <div className="RCM_label_wrapper" key="PAMP003">
                                        <Label for="type" key="PAMP004"
                                            title="Please select the client associated with this Entity">
                                            Select Client:
                                        </Label>
                                </div>

                                <Dropdown name="partyId" required
                                    isOpen={isPartyIdopen}
                                    toggle={() => setIsPartyIdopen(prevIsPartyIdopen => !prevIsPartyIdopen)}
                                        className="RCM_form_control RCM_select_box RCM_mm_select_box"
                                        key="PAMP005"
                                    title="Please select the client associated with this Entity"                                    >
                                        <DropdownToggle caret key="PAMP006">{paymentPartyId.partyname}</DropdownToggle>
                                        <DropdownMenu key="PAMP007">
                                        {
                                            partyIdList.map((item, index) =>
                                                <DropdownItem key={index} onClick={() => setPaymentPartyId({ ...item })}>
                                                    <div key={"PAMP001"+index}>{item.partyname}</div>
                                                </DropdownItem>
                                            )}
                                    </DropdownMenu>
                                </Dropdown>
                            </FormGroup>
                            </div>

                            :''
                        }
                        
                    </div>

                </ModalBody>
                <ModalFooter className="RCM_manage_group_footer" key="PAMP09">
                    <button type="button" className="btn btn-secondary RCM_button_secondary" onClick={() => togglePaymentsAccountModelPopup()} key="PAMP10">CANCEL</button>
                    <button type="button" className="btn btn-primary RCM_button_primary" onClick={() => save()} key="PAMP11">SAVE</button>
                </ModalFooter>
            </Modal>
            
        </>
    )
}
export default PaymentAccountPopup;